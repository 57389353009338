import React from "react";
import Loader from "../Loaders/Loader";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";
import localFont  from "next/font/local";

const UserLoadingScreen = ({ user, ProtectedRoutes, children }: any) => {
  const router = useRouter();
  const currentPage = router?.route;
  const isProtectedRoutes =
    ProtectedRoutes.pages.includes(currentPage) ||
    ProtectedRoutes.pagesWithCheck.includes(currentPage);

  if (isProtectedRoutes) {
    const isPagesWithCheck =
      ProtectedRoutes.pagesWithCheck.includes(currentPage);
    const shouldShowLoading = isPagesWithCheck
      ? user === "loading" || user?.id
      : user === "loading" || !user;
    const router = useRouter();
    const searchParams = useSearchParams();
    const redirectTo = searchParams?.get("redirectTo");

    if (!user && user !== "loading" && !isPagesWithCheck) {
      router.push(`/start?redirectTo=${currentPage}`);
    }
    if (user !== "loading" && user?.id && isPagesWithCheck) {
      router.push(`${redirectTo ? redirectTo : "/"}`);
    }

    if (shouldShowLoading) {
      return (
        <div className="fixed w-screen font-bold h-screen bg-white top-0 left-0 flex flex-col gap-3 justify-center items-center">
          <span className="text-6xl text-[#15BC6E] py-7">
            Dinnerfy
          </span>
          <p>What's for dinner?</p>
          <Loader />

        </div>
      );
    }

    return children;
  } else {
    return children;
  }
};

export default UserLoadingScreen;
