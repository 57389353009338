import { useEffect, useState } from "react";
import router from "next/router";

import { supabase } from "@/lib/supabaseClient";

export function useUser() {
  const [user, setUser] = useState("loading");

  useEffect(() => {
    getUser();

    const { data } = supabase.auth.onAuthStateChange((event) => {
        getUser();
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  async function getUser() {
    const user = await supabase.auth.getUser() as any;
      setUser(user.data.user);
  }

  return user;
}