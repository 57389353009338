import React, {createContext, useContext, useEffect} from "react";
import {useRouter} from "next/router";
import {boot as bootIntercom, load as loadIntercom, show, shutdown, update as updateIntercom} from "./intercom";
import {useUser} from "@supabase/auth-helpers-react";

export const IntercomContext = createContext();
export const IntercomProvider = ({children}) => {
    const router = useRouter();
    const user = useUser();

    if (typeof window !== "undefined") {
        loadIntercom();
        bootIntercom();
    }

    useEffect(() => {
        const handleRouteChange = (url) => {
            if (typeof window !== "undefined") {
                updateIntercom();
            }
        };

        router.events.on("routeChangeStart", handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        if (user) {
            updateIntercom({
                ...user,
                user_id: user?.id,
                name: user?.user_metadata?.name ?? user?.user_metadata?.full_name,
                avatar_url:user?.user_metadata?.avatar_url
            })
        }
    }, [user])

    return (
        <IntercomContext.Provider
            value={{
                show,
                shutdown
            }}
        >
            {children}
        </IntercomContext.Provider>
    );
};

export const useIntercom = () => {
    const intercomContext = useContext(IntercomContext);

    if (!intercomContext) {
        throw new Error("useIntercom must be used within a IntercomProvider");
    }

    const {
        show,
        shutdown
    } = intercomContext;

    return {
        show,
        shutdown
    };
};
