import {createContext, ReactNode, useContext, useEffect, useState,} from "react";
import {useUser} from "@supabase/auth-helpers-react";


export const AnalyticsContext = createContext<any>({
  analytics: undefined,
});

interface UserProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: UserProviderProps) => {
  const [analytics, setAnalytics] = useState<any | undefined>();
  const user = useUser();

  useEffect(() => {
    // if(window?.analytics){
    //   setAnalytics(window?.analytics)
    //   if(user) {
    //     window.analytics.identify(user?.id, {
    //       email: user?.email,
    //     })
    //   }
    // }
    // @ts-ignore
    if(window?.rudderanalytics){
      // @ts-ignore
      setAnalytics(window?.rudderanalytics)
      if(user) {
        // @ts-ignore
        window.rudderanalytics.identify(user?.id, {
          email: user?.email,
        })
      }
    }

  }, [user]);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const { analytics } = useContext(AnalyticsContext);
  return analytics;
};
