import CartItem from "@/components/Shopping/CartItem";
import React, {createContext, useContext, useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useSupabaseClient, useUser} from "@supabase/auth-helpers-react";
import {v4 as uuidv4} from "uuid";

type CartItem = {
    id: number;
    ingredient_id?: string;
    quantity?: string;
    ingredient?: any;
    serving?: any;
    sourceRecipes?: any;
};

type CartContextType = {
    cartItems: any[];
    addItemToCart: (item: CartItem, recipe: any) => void;
    removeItemFromCart: (item: any, recipe: any) => void;
    removeIngredientFromCart: (item: any) => void;itemCount: number;
    addMoreItems: (item: any) => void;
    removeMoreItems: (item: any) => void;
    moreItems: any[];
    addAllIngredientsToCart: (ingredients: any[]) => void;
    updateMoreItems: (updatedItem: any) => void;
    shoppingDone: () => void;
};

export const CartContext = createContext<CartContextType | undefined>(
    undefined
);

interface CartProviderProps {
    children: React.ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({children}) => {
    const [cart, setCart] = useState<any>()
    const [cartItems, setCartItems] = useState<any[]>([]);
    const [moreItems, setMoreItems] = useState<any[]>([]);
    const supabaseClient = useSupabaseClient();
    const user = useUser();
    useEffect(() => {
        const uniqueIds = new Set();
        const filteredData = cartItems.filter((item) => {
            if (item.sourceRecipes.length === 0 || uniqueIds.has(item.id)) {
                return false;
            }
            uniqueIds.add(item.id);
            return true;
        });

        if (filteredData.length !== cartItems.length) {
            setCartItems(filteredData);
        }
    }, [cartItems]);

    // useEffect(() => {
    //     const storedItems = localStorage.getItem("moreItems");
    //
    //     if (storedItems) {
    //         setMoreItems(JSON.parse(storedItems));
    //     } else if (moreItems.length === 0) {
    //         setMoreItems([]);
    //     }
    // }, []);

    useEffect(() => {
        if (user) {
            fetchCart();
        }
    }, [user])

    const fetchCart = async () => {
        const {data: cartData, error} = await supabaseClient.from('cart').select().limit(1).maybeSingle()
        if (cartData) {
            setCart(cartData)
            setCartItems(cartData?.items)
        } else {
            setCart({
                id: uuidv4(),
                items: []
            })
        }
    }

    const syncCart = async () => {
        const {data: cartData} = await supabaseClient.from('cart').upsert([{
            id: cart?.id,
            items: cartItems
        }]).select()
            .limit(1)
            .maybeSingle();

        if (cartData) {
            setCart(cartData)
        }
    }

    useEffect(() => {
        if (cart) {
            syncCart()
        }
    }, [cartItems])

    // Function to add an item to the cart
    const addMoreItems = (item: any) => {
        setMoreItems((p) => [...p, item]);
        // localStorage.setItem("moreItems", JSON.stringify([...moreItems, item]));
    };

    const updateMoreItems = (updatedItem: any) => {
        setMoreItems((prevItems) => {
            const updatedItems = prevItems.map((item) => {
                if (item.id === updatedItem.id) {
                    return {...item, ...updatedItem};
                }
                return item;
            });

            // localStorage.setItem("moreItems", JSON.stringify(updatedItems));
            return updatedItems;
        });
    };

    const removeMoreItems = (item: any) => {
        const updatedItem = moreItems.filter((r) => r.id !== item?.id);
        setMoreItems(updatedItem);
        localStorage.setItem("moreItems", JSON.stringify(updatedItem));
    };

    const addItemToCart = (item: any, recipeReference?: any) => {
        setCartItems((p) => {
            const existingItem = p.find(
                (cartItem) => cartItem.id === item.ingredientID
            );
            if (existingItem) {
                return p.map((cartItem) => {
                    if (cartItem?.id === item?.ingredientID) {
                        let updatedItem = cartItem;
                        // check if recipe already exist or not
                        const recipeExist = cartItem?.sourceRecipes?.find(
                            (r: any) => r?.id === recipeReference?.id
                        );
                        if (!recipeExist) {
                            updatedItem.sourceRecipes = [
                                ...updatedItem?.sourceRecipes,
                                recipeReference,
                            ];
                        }
                        return updatedItem;
                    }
                    return cartItem;
                });
            } else {
                return [
                    ...p,
                    {
                        id: item?.ingredientID,
                        ingredient: item?.ingredientName,
                        sourceRecipes: [recipeReference],
                    },
                ];
            }
        });
    };

    // Function to remove an item from the cart
    const removeIngredientFromCart = (item: any) => {
        const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
        if (existingItem) {
            let updatedCartItems = [];
            updatedCartItems = cartItems.filter(
                (cartItem) => cartItem.id !== item.id
            );
            setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };

  const removeItemFromCart = (item: any, recipeReference?: any) => {
    const existingItem = cartItems.find(
      (cartItem) => cartItem.id === item.ingredientID
    );
    if (existingItem) {
      let updatedCartItems = [];
      if (recipeReference) {
        updatedCartItems = cartItems.filter((cartItem) => {
          if (cartItem.id === item.ingredientID) {
            let updatedItem = cartItem;

            updatedItem.sourceRecipes = updatedItem.sourceRecipes.filter(
              (r: any) => r.id !== recipeReference?.id
            );
            if (updatedItem?.sourceRecipes?.length === 0) {
              return null;
            }
            return updatedItem;
          }
          return cartItem;
        });
      } else {
        updatedCartItems = cartItems.filter(
          (i) => i.ingredientID !== item?.ingredientID
        );
      }
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        }
    };

    // save the order in db after shopping done
    const shoppingDone = async () => {
        if (cart) {
            const {data: orderData, error: orderError} = await supabaseClient.from('order').insert([cart]).select();
            console.log(orderData, orderError)

                await supabaseClient.from('cart').delete().eq('id', cart?.id)
                setCart({
                    id: uuidv4(),
                    items: []
                })
            setCartItems([])
            setMoreItems([])

        } else {
            console.log("cart", cart)
        }
    }

    const addAllIngredientsToCart = (ingredients: CartItem[]) => {
        const updatedCartItems = [...cartItems, ...ingredients];
        setCartItems(updatedCartItems);
        toast.success("All ingredients added to cart");
    };
    const itemCount = cartItems.length;

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addItemToCart,
                removeItemFromCart,
                removeIngredientFromCart,addMoreItems,
                removeMoreItems,
                itemCount,
                moreItems,
                addAllIngredientsToCart,
                updateMoreItems,
                shoppingDone
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const cart = useContext(CartContext);

    if (!cart) {
        throw new Error("useCart must be used within a CartProvider");
    }

    const {
        cartItems,
        addItemToCart,
        removeItemFromCart,
        removeIngredientFromCart,itemCount,
        addMoreItems,
        removeMoreItems,
        moreItems,
        addAllIngredientsToCart,
        updateMoreItems,
        shoppingDone
    } = cart;

    return {
        cartItems,
        addItemToCart,
        removeItemFromCart,
        removeIngredientFromCart,itemCount,
        addMoreItems,
        removeMoreItems,
        moreItems,
        addAllIngredientsToCart,
        updateMoreItems,
        shoppingDone
    };
};
