import { Session, SessionContextProvider } from "@supabase/auth-helpers-react";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import "@/styles/globals.css";
import "@/styles/nprogress.css";
import { supabase } from "@/lib/supabaseClient";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import localFont from "next/font/local";
import { CartProvider } from "@/contexts/cartContext";
import { useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { AuthChangeEvent } from "@supabase/gotrue-js/src/lib/types";
import { AnalyticsProvider } from "@/contexts/analyticsContext";
import { IntercomProvider } from "@/contexts/intercom/IntercomProvider";
import { useUser } from "@/hooks/useUser";
import UserLoadingScreen from "@/components/UserLoadingScreen/UserLoadingScreen";
import ProtectedRoutes from "../data/ProtectedRoutes.json";
import dynamic from "next/dynamic";

const TopProgressBar = dynamic(
  () => {
    return import("@/components/TopProgressBar");
  },
  { ssr: false }
);

// Check that PostHog is client-side (used to handle Next.js SSR)

if (typeof window !== "undefined") {
  // if (process.env.NODE_ENV !== "development") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    opt_in_site_apps: true,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
  // }
}

const generalSans = localFont({
  src: [
    { path: "../public/fonts/GeneralSans-Bold.woff2", weight: "700" },
    { path: "../public/fonts/GeneralSans-Light.woff2", weight: "300" },
    { path: "../public/fonts/GeneralSans-Medium.woff2", weight: "500" },
    { path: "../public/fonts/GeneralSans-Regular.woff2", weight: "400" },
    { path: "../public/fonts/GeneralSans-Semibold.woff2", weight: "600" },
  ],
  variable: "--font-generalSans",
});

export default function App({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session;
}>) {
  const router = useRouter();
  Router.events.on("routeChangeComplete", (url) => {
    // @ts-ignore
    if (window?.rudderanalytics) {
      // @ts-ignore
      window.rudderanalytics.page(url);
    }
  });
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    Router.events.on("routeChangeComplete", handleRouteChange);

    supabase.auth.onAuthStateChange(
      (event: AuthChangeEvent, session: Session | null) => {
        // useUser();
        if (event === "SIGNED_OUT") {
          // delete cookies on sign out
          const expires = new Date(0).toUTCString();
          document.cookie = `sp-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
          document.cookie = `sp-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
        } else if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
          const maxAge = 100 * 365 * 24 * 60 * 60; // 100 years, never expires
          // @ts-ignore
          document.cookie = `sp-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
          // @ts-ignore
          document.cookie = `sp-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        }
      }
    );

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  const user = useUser() as any;

  return (
    <SessionContextProvider
      supabaseClient={supabase}
      initialSession={pageProps.initialSession}
    >
      <PostHogProvider client={posthog}>
        <CartProvider>
          <AnalyticsProvider>
            <IntercomProvider>
              <UserLoadingScreen user={user} ProtectedRoutes={ProtectedRoutes}>
                <div className={`${generalSans.variable} font-sans`}>
                  <TopProgressBar />
                  <Component {...pageProps} />
                  <Analytics />
                </div>
              </UserLoadingScreen>
            </IntercomProvider>
          </AnalyticsProvider>
        </CartProvider>
      </PostHogProvider>
    </SessionContextProvider>
  );
}
